@import "../../assets/variables/color.scss";

.posterContainer {
  position: fixed;
  padding: 0 10px;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);

  @media (max-height: 375px) {
    display: none;
  }

  @media (min-width: 475px) and (max-width: 992px) {
    height: 70vw;
    width: 70vw;
    padding: 0;
  }

  @media (min-width: 992px) and (max-width: 1370px) {
    padding: 0;
    position: static;
    width: 55vw;
    height: 55vw;
    border-radius: 24px;
    transform: none;
  }

  @media screen and (min-width: 1370px) {
    padding: 0;
    position: static;
    width: 45vw;
    height: 45vw;
    border-radius: 24px;
    transform: none;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-position: center top;
  border-radius: 24px;
  object-fit: cover;

  @media (min-width: 475px) and (max-width: 992px) {
    width: 75vw;
    margin: 0 auto;
  }
}

.resultContainer {
  border-radius: 24px;
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 55vh;
  width: calc(100% - 20px);
  margin: 0 10px;

  @media (max-height: 414px) {
    height: 100vh !important;
  }

  @media (min-width: 992px) and (max-width: 1370px) {
    background-color: $white;
    position: static;
    width: 40vw;
    height: 55vw;
    border-radius: 24px;
    margin: 0;

    display: flex;
    align-items: center;
    padding: 0 2%;
  }

  @media (min-width: 475px) and (max-width: 991px) {
    width: 70vw;
    height: 55vh;
    background-color: $white;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 1370px) {
    background-color: $white;
    position: static;
    width: 40vw;
    height: 45vw;
    border-radius: 24px;
    margin: 0;

    display: flex;
    align-items: center;
    padding: 0 2%;
  }
}

.resultContainerBox {
  padding: 24px 16px;
  border-radius: 24px;
  background-color: $white;
  min-height: 55vh;

  @media screen and (min-width: 992px) {
    padding: 0;
    border-radius: 24px;
  }
}

.resultContainerBox::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s;

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.onScrollOption::before {
  background-color: rgba(0, 0, 0, 0.6);
  top: 8px;
  transition: all 0.2s;
}

.promoWrapper {
  display: flex;
  width: 100%;
}

.inputPromoWrapper {
  width: 100%;
  display: flex;
  position: relative;

  @media screen and (min-width: 992px) {
    width: auto;
  }
}

.form {
  width: 100%;

  @media screen and (min-width: 992px) {
    width: auto;
  }
}

.inputPromo {
  border: 0;
  background-color: $white;
  padding: 14px 24px;
  border-radius: 32px;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  outline: none;

  @media (min-width: 992px) and (max-width: 1370px) {
    background-color: $white-bg;
    width: auto;
    font-size: 20px;
    line-height: 32px;
    padding: 10px 24px;
  }

  @media screen and (min-width: 1370px) {
    width: auto;
    font-size: 20px;
    line-height: 32px;
  }
}

.copy {
  position: absolute;
  right: 8px;
  top: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 32px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $white;

  &:hover {
    background-color: $white-bg;
    transition: all 0.2s;
  }

  @media (min-width: 1370px) {
    top: 10px;
  }
}

.copySuccess {
  background-color: $accent-diasable;
  position: absolute;
  left: 24px;
  top: 2vh;
  padding: 16px 32px;
  border-radius: 8px;
  transition: all 0.2s;
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
    bottom: 2%;
    left: 5.5%;
    top: auto;
  }
}

.displayNone {
  opacity: 0;
  transition: all 0.2s;
}

.promoButtonsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0;
  margin-top: 16px;

  @media screen and (min-width: 992px) {
    gap: 16px 24px;
    margin-top: 2.5%;
  }
}

.buttonPresent {
  width: 100%;
  position: static;
  margin: 0;

  @media screen and (min-width: 992px) {
    width: calc(100vw - 32px);
  }
}

.paragraph {
  padding: 0;

  @media (min-width: 992px) and (max-width: 1370px) {
    display: none;
  }
}

.promoBox {
  margin-top: 24px;
  background-color: $white-bg;
  padding: 3.5%;
  border-radius: 32px;

  @media (min-width: 992px) and (max-width: 1370px) {
    background-color: $white;
    padding: 0;
    margin-top: 0;
  }

  @media screen and (min-width: 1370px) {
    margin-top: 4%;
  }
}

.buttonShare {
  position: static;
  margin-top: 16px;
  width: 100%;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.socialBox {
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
  }
}

.social {
  @media (min-width: 992px) {
    margin-top: 8px;
    display: flex;
    gap: 0 8px;
  }
}

.icon {
  border: 2px solid $text;
  border-radius: 50%;
  transition: all 0.2s;
  height: 40px;
  width: 40px;

  &:hover {
    opacity: 0.8;
  }
}

.icon circle {
  fill: $white;
  transition: all 0.2s;
}

.icon path {
  fill: $text;
  transition: all 0.2s;
}

.buttonBox {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  width: 100%;

  @media (min-width: 992px) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.buttonBoxLink {
  text-align: center;
  text-decoration: underline;
  color: $text;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transition: all 0.2s;
  }
}

.label {
  padding-top: 16px;
  font-size: 14px;
  line-height: 24px;
  transition: all 0.2s;

  & > a {
    color: $text;
  }

  &:hover {
    opacity: 0.8;
    transition: all 0.2s;
  }

  @media (min-width: 992px) {
    padding-top: 2.5%;
    font-size: calc(14px + 0.3125vw);
    line-height: calc(24px + 0.4296875vw);
  }
}

.buttonConditions {
  position: static;
  margin-top: 8%;
  width: 100%;
}

.parBox {
  margin-top: 5%;
  margin-bottom: 2.5%;
  padding: 3.5%;
  font-size: 14px;
  line-height: 24px;
  background-color: $white-bg;
  border-radius: 32px;

  @media screen and (min-width: 992px) {
    padding-top: 2.5%;
    font-size: calc(14px + 4 * (100vw / 1280));
    line-height: calc(24px + 5.5 * (100vw / 1280));
  }
}

.link {
  color: $text;
  transition: 0.2s;

  &:hover {
    transition: 0.2s;
    opacity: 0.8;
  }
}
